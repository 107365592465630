import { Region } from 'shared/enums/region.enum';
import { getRegion } from 'providers/region.provider';
import { useSelector } from 'app/hooks/store/use-selector.hook';

const CustomerSupportEmails = {
	[Region.NORTH_AMERICA]: 'navispherecarrier@chrobinson.com',
	[Region.EUROPE]: 'carrier.qualityeu@chrobinson.com',
	[Region.BRAZIL]: 'soportenc@chrobinson.com',
	[Region.ARGENTINA]: 'soportenc@chrobinson.com',
	[Region.CANADA]: 'navispherecarrier@chrobinson.com',
	[Region.UNITED_STATES]: 'navispherecarrier@chrobinson.com',
	[Region.MEXICO]: 'MxCarrierServices@chrobinson.com',
};

const CustomerSupportNumbers = {
	[Region.NORTH_AMERICA]: '1-800-326-9977',
	[Region.EUROPE]: '+48 22 320 99 95',
	[Region.BRAZIL]: '+55 11 2397-0710',
	[Region.ARGENTINA]: '+54 01 1555-6300',
	[Region.CANADA]: '1-800-326-9977',
	[Region.UNITED_STATES]: '1-800-326-9977',
	[Region.MEXICO]: '01-55-5080-5076',
};

export const useCustomerSupportEmail = (country?: CountryResponse) => {
	const defaultCountry = useSelector<CountryResponse>(state => state.app.defaultCountry);
	const cultureCountry = useSelector<GlobalizationCountry>(state => state.culture?.country);

	return CustomerSupportEmails[getRegion(country || defaultCountry || cultureCountry)];
};

export const useCustomerSupportNumber = (country?: CountryResponse) => {
	const defaultCountry = useSelector<CountryResponse>(state => state.app.defaultCountry);
	const cultureCountry = useSelector<GlobalizationCountry>(state => state.culture?.country);

	return CustomerSupportNumbers[getRegion(country || defaultCountry || cultureCountry)];
};
