import { Region } from 'shared/enums/region.enum';

const COUNTRY_REGION = {
  US: Region.UNITED_STATES, // United States
  CA: Region.CANADA, // Canada
  MX: Region.MEXICO, // Mexico
  AX: Region.EUROPE, // Aland Islands
  AL: Region.EUROPE, // Albania
  AD: Region.EUROPE, // Andorra
  AT: Region.EUROPE, // Austria
  BY: Region.EUROPE, // Belarus
  BE: Region.EUROPE, // Belgium
  BA: Region.EUROPE, // Bosnia and Herzegovina
  BG: Region.EUROPE, // Bulgaria
  HR: Region.EUROPE, // Croatia
  CY: Region.EUROPE, // Cyprus
  CZ: Region.EUROPE, // Czech Republic
  DK: Region.EUROPE, // Denmark
  EE: Region.EUROPE, // Estonia
  FO: Region.EUROPE, // Faroe Islands
  FI: Region.EUROPE, // Finland
  FR: Region.EUROPE, // France
  DE: Region.EUROPE, // Germany
  GI: Region.EUROPE, // Gibraltar
  GR: Region.EUROPE, // Greece
  GG: Region.EUROPE, // Guernsey
  HU: Region.EUROPE, // Hungary
  IS: Region.EUROPE, // Iceland
  IM: Region.EUROPE, // Isle of Man
  IT: Region.EUROPE, // Italy
  JE: Region.EUROPE, // Jersey
  XK: Region.EUROPE, // Kosovo
  LV: Region.EUROPE, // Latvia
  LI: Region.EUROPE, // Liechtenstein
  LT: Region.EUROPE, // Lithuania
  LU: Region.EUROPE, // Luxembourg
  MK: Region.EUROPE, // Macedonia
  MT: Region.EUROPE, // Malta
  MD: Region.EUROPE, // Republic of Moldova
  MC: Region.EUROPE, // Monaco
  ME: Region.EUROPE, // Montenegro
  NL: Region.EUROPE, // Netherlands
  IE: Region.EUROPE, // Ireland
  NO: Region.EUROPE, // Norway
  PL: Region.EUROPE, // Poland
  PT: Region.EUROPE, // Portugal
  RO: Region.EUROPE, // Romania
  RU: Region.EUROPE, // Russian Federation
  SM: Region.EUROPE, // San Marino
  RS: Region.EUROPE, // Serbia
  SK: Region.EUROPE, // Slovakia
  SI: Region.EUROPE, // Slovenia
  ES: Region.EUROPE, // Spain
  SJ: Region.EUROPE, // Svalbard and Jan Mayen
  SE: Region.EUROPE, // Sweden
  CH: Region.EUROPE, // Switzerland
  TR: Region.EUROPE, // Turkey
  UA: Region.EUROPE, // Ukraine
  GB: Region.EUROPE, // United Kingdom
  VA: Region.EUROPE, // Vatican,
  BR: Region.BRAZIL,
  AR: Region.ARGENTINA
};

export const getRegion = (country: GlobalizationCountry|CountryResponse) => {
  return (country?.code in COUNTRY_REGION) ? COUNTRY_REGION[country.code] : Region.NORTH_AMERICA;
};
